import React from "react";
import { Routes,  Route,} from "react-router-dom";
import Home from "../components/Home";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
const Rotues = ()=> {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>} />
          <Route path="/portfolio" element={<Portfolio/>} />
          <Route path="/contact" element={<Contact/>} />
      </Routes>
    </div>
  )
}

export default Rotues;
