import React from "react";

const Portfolio = () => {
  return (
    <div>
      Portfolio
    </div>
  )
}

export default Portfolio;
